@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100..900;1,100..900&display=swap');

.raleway-900 {
  font-family: "Raleway", sans-serif;
  font-optical-sizing: auto;
  font-weight: 900;
  font-style: italic;
}

.raleway-sb-600 {
  font-family: "Raleway", sans-serif;
  font-optical-sizing: auto;
  font-weight: 600;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.fade-in-up {
  opacity: 0;
  transform: translateY(20px);
  animation: fadeInUp 1s ease-in-out forwards;
}

.fade-in {
  opacity: 0;
  animation: fadeInUp 0.5s ease-in-out forwards;
}

.highlighted-fuchsia {
  background-image: linear-gradient(to right, rgb(240 171 252 / var(--tw-bg-opacity)) 50%, white 50%);
  background-size: 200%;
  background-position: 100% 0;
  animation: swipe 0.3s forwards 0.5s;
}

@media (prefers-color-scheme: dark) {
  .highlighted-fuchsia {
    background-image: linear-gradient(to right, rgb(240 171 252 / var(--tw-bg-opacity)) 50%, rgb(17 24 39 / var(--tw-bg-opacity)) 50%);
  }
}

.highlighted-lime {
  background-image: linear-gradient(to right, rgb(217 249 157 / var(--tw-bg-opacity)) 50%, white 50%);
  background-size: 200%;
  background-position: 100% 0;
  animation: swipe 0.3s forwards 0.5s;
}

@media (prefers-color-scheme: dark) {
  .highlighted-lime {
    background-image: linear-gradient(to right, rgb(217 249 157 / var(--tw-bg-opacity)) 50%, rgb(17 24 39 / var(--tw-bg-opacity)) 50%);
  }
}

@keyframes swipe {
  from {
    background-position: 100% 0; /* Start from the right */
  }
  to {
    background-position: 0 0; /* End at the left */
  }
}

@keyframes fadeInUp {
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeInUp {
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeIn {
  to {
    opacity: 1;
  }
}

.grecaptcha-badge {
  visibility: hidden;
}

.readability {
  user-select: contain;

  h1{
    @apply mb-4 text-4xl font-extrabold;
  }
  h2{
    @apply mb-4 text-2xl font-bold;
  }
  h3{
    @apply mb-4 text-xl font-bold;
  }
  h4{
    @apply mb-4 text-lg font-bold;
  }
  hr {
    @apply hidden;
  }
  p {
    @apply pb-3;
  }
  time {
    @apply text-gray-500;
  }
}

.readabilityp::selection {
  color: #fffaa5;
  background-color: #f38630;
  text-shadow: 2px 2px #31808c;
}


